import React, { useState, useEffect } from "react";

// Misc Imports
import { RedirectData, Theme } from "@app-types";
import { useTranslation } from "@i18n";
import {
  makeStyles,
  activatedTransition,
  cardVariants,
  redirectTransition,
  redirectVariants,
} from "@styles";
import { isServerSideRendering, navigate } from "@utils";

// Library imports
import { AnimatePresence, motion } from "framer-motion";

// Component imports
import { Image, PayoutBanner, ProgressBar } from "@components";
import { CheckCircleIcon } from "@icons";
import { Grid, Paper, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  redirectContainer: {
    minHeight: "100vh",
    backgroundColor: "#3a3a3a",
  },
  paper: {
    margin: "auto",
    backgroundColor: "white",
    borderRadius: 8,
    height: "auto",
    minHeight: 486,
    width: 459,
    outline: "none !important",
    padding: 45,
    border: "1px solid #E6E6E6",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      padding: 32,
      minHeight: 442,
      maxWidth: 320,
    },
  },
  loadingContainer: {
    width: "90%",
    height: 99,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  activatedContainer: {
    width: "90%",
    height: "auto",
  },
  header: {
    marginTop: 5,
    marginBottom: 16,
    fontSize: 36,
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
    },
  },
  logoContainer: {
    minHeight: 110,
    marginTop: 16,
    marginBottom: 16,
  },
  logo: {
    width: 110,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 12,
    },
  },
  cashbackContainer: {
    marginBottom: 8,
  },
  progressContainer: {
    marginTop: 24,
    marginLeft: "auto",
    marginRight: "auto",
    width: "65%",
  },
  rewardsTextContainer: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  rewardsText: {
    fontSize: 19.7,
    textAlign: "center",
    marginTop: 44,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16.4,
      marginTop: 40,
    },
  },
  activatedText: {
    fontSize: 30,
    textAlign: "center",
    marginTop: 32,
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
      marginTop: 24,
    },
  },
  divider: {
    color: "white",
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid #888888",
    lineHeight: "0.1em",
    marginBottom: 8,
  },
  dividerLine: {
    background: "white",
    padding: "0 8px",
  },
  activatedIconContainer: {
    color: "white",
    width: "100%",
    textAlign: "center",
    marginBottom: 8,
  },
}));

export default function Redirect() {
  if (isServerSideRendering()) {
    return <></>;
  }

  const classes: any = useStyles({});
  const { t } = useTranslation("onlineScreen");

  const [offerStatus, setOfferStatus] = useState<string>("");
  const [redirectComplete, setRedirectComplete] = useState<boolean>(false);

  const redirectData: RedirectData = JSON.parse(
    localStorage.getItem("redirectData"),
  );

  if (!redirectData) navigate("/sign-in", { replace: true });

  // Redirect functions and useEffects to handle redirect flow
  // Step One
  const startLoadingTimer = (): void => {
    setTimeout(() => {
      setOfferStatus("valid");
    }, 3000);
  };
  useEffect(startLoadingTimer, [redirectData]);

  //Step Two
  const startRedirectTimer = (): void => {
    if (offerStatus === "valid") {
      setTimeout(async () => {
        setRedirectComplete(true);
      }, 500);
    }
  };
  useEffect(startRedirectTimer, [offerStatus]);

  // Step Three
  const initiateRedirect = (): void => {
    if (redirectComplete) {
      window.location.replace(redirectData.redirectUrl);
      localStorage.removeItem("redirectData");
    }
  };
  useEffect(initiateRedirect, [redirectComplete]);

  // Clears redirect Data if window is closed
  window.addEventListener("beforeunload", () => {
    localStorage.removeItem("redirectData");
  });

  return !redirectData ? null : (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.redirectContainer}
    >
      {redirectData && (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={redirectVariants}
          transition={redirectTransition}
          className={classes.activatedContainer}
        >
          <Paper className={classes.paper}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.container}
            >
              <Typography
                className={classes.header}
                style={{ lineHeight: "30px" }}
              >
                {offerStatus === "invalid"
                  ? t("redirect.expiredOffer")
                  : t("redirect.redirecting")}
              </Typography>
              <Grid
                container
                item
                alignItems="center"
                justify="center"
                className={classes.logoContainer}
              >
                <Image
                  image={redirectData.advertiserLogoImage}
                  className={classes.logo}
                  alt="advertiser-logo"
                />
              </Grid>
              <Grid
                container
                item
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.cashbackContainer}
              >
                <PayoutBanner payoutText={redirectData.payoutText} />
              </Grid>
              {offerStatus === "valid" ? (
                <motion.div
                  initial="initial"
                  animate="in"
                  variants={cardVariants}
                  transition={activatedTransition}
                  className={classes.activatedContainer}
                >
                  <div className={classes.divider}>
                    <span className={classes.dividerLine}>
                      <CheckCircleIcon
                        width={56}
                        fill={"#00D40E"}
                        style={{
                          position: "relative",
                          top: 16,
                          height: 32,
                        }}
                      />
                    </span>
                  </div>
                  <Typography className={classes.activatedText}>
                    <span
                      style={{ fontWeight: 800, marginTop: 4, marginBottom: 4 }}
                    >
                      {t("redirect.activated")}
                    </span>
                    <br /> {t("redirect.happyShopping")}
                  </Typography>
                </motion.div>
              ) : offerStatus === "invalid" ? (
                <div className={classes.loadingContainer}>
                  <div className={classes.activatedIconContainer}></div>
                  <Typography className={classes.rewardsText}>
                    {t("redirect.oops")}
                    <br />
                    {t("redirect.pleaseTry")}
                    <br />
                    <strong>{t("redirect.report")}</strong>
                  </Typography>
                </div>
              ) : (
                <AnimatePresence>
                  <motion.div
                    initial="in"
                    exit="out"
                    variants={cardVariants}
                    transition={activatedTransition}
                    className={classes.loadingContainer}
                  >
                    <Grid
                      item
                      container
                      justify="center"
                      className={classes.progressContainer}
                    >
                      <ProgressBar />
                    </Grid>
                    <Grid item className={classes.rewardsTextContainer}>
                      <Typography className={classes.rewardsText}>
                        {t("redirect.rewardsMessage.one")}
                        <strong>{t("redirect.rewardsMessage.two")}</strong>
                        {t("redirect.rewardsMessage.three")}
                      </Typography>
                    </Grid>
                  </motion.div>
                </AnimatePresence>
              )}
            </Grid>
          </Paper>
        </motion.div>
      )}
    </Grid>
  );
}
